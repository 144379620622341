import dynamic from 'next/dynamic';
import React from 'react';

import { ClaimsJourneyWrapper } from '@client/components/Layout';

import { CommonRoutes } from '../../client/constants/routes';
import { withAuthenticatedUserSSR } from '../../server/utils/withAuthenticatedUserSSR';

const NoSSRlogin = dynamic(() => import('../../client/modules/Login'), {
  ssr: false,
});

const LoginPage = () => {
  return (
    <ClaimsJourneyWrapper>
      <NoSSRlogin />
    </ClaimsJourneyWrapper>
  );
};

export const getServerSideProps = withAuthenticatedUserSSR({
  onGrantedAccess: async () => {
    return {
      redirect: {
        permanent: false,
        destination: CommonRoutes.getWelcomeRoute(),
      },
    };
  },
  onDeniedAccess: async () => {
    return {
      props: {},
    };
  },
});

export default LoginPage;
